
//local:
//var BASE_URL="http://localhost:65083/ioasis/api/";

//production:
var BASE_URL="https://indianaonlineacademy.org/ioasis/api/";

export var school = {
    list: function(schoolName = ""){
        return BASE_URL + "school/listSchools?name=" + schoolName
    },
}

export var instructor = {
    list: function(){
        return BASE_URL + "user/listInstructors"
    }
}

export var semester = {
    list: function(currentAndFutureOnly = false){
        return BASE_URL + "semester/listSemesters?CurrentAndFutureOnly=" + currentAndFutureOnly
    }
}

export var course = {
    list: function(semesterID = ""){
        return BASE_URL + "course/listCourses?SemesterID=" + semesterID
    }
}



