
if (!global._babelPolyfill) {
	require('babel-polyfill');
}

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
var accordion = require('jquery-ui/ui/widgets/accordion');
var dialog = require('jquery-ui/ui/widgets/dialog');
var button = require('jquery-ui/ui/widgets/button');
var tooltip = require('jquery-ui/ui/widgets/tooltip');

import {school, instructor, semester, course} from "./constants"
import loaderImage from '../img/ajax-loader.gif';

var dt = require( 'datatables.net-dt' );
var dtfh = require( 'datatables.net-fixedheader-dt' );

var _ = require('lodash');


function generateLoader(){
    return "<div id='loading' style='text-align:center;width:100%;'>" + 
        "Loading<br/>" + 
        "<img src='" + loaderImage + "'/>" +
    "</div>";
}
function removeLoader(){
    $("#loading").remove();
}

export async function getData(url){   
    let result = {};

    try {
        return $.ajax({
            url: url,
            contentType: 'application/json',
            type: 'GET',
            //data: args
        }).done( (data)=>{
            return result = data;
        }).fail(err=>{
            return result = JSON.stringify(err);
        });
        return result;

    } catch (error) {
        console.error(error);
    }
}


export async function getParticipatingSchools(element, onComplete){

    let output = $("<table id='schoolsTable'></table>");
    $(output).append("<thead><tr><th>School Name</th><th>Fall/Spring Cost to Student</th><th>Summer Cost to Student</th></tr></thead>");

    $(element).append(generateLoader());

    let schoolsArray = await getData(school.list());
    schoolsArray = JSON.parse(schoolsArray);
    schoolsArray = schoolsArray.sort((a, b) => a.SchoolName.localeCompare(b.SchoolName));
        
    let tbody = "";

    for(let school of schoolsArray){
        tbody += 
            "<tr>" +
                "<td>" + school.SchoolName + "</td>" +
                "<td>$" + school.CostToStudent.toFixed(2) + "</td>" + 
                "<td>$" + (school.SummerPricing ? school.SummerCostToStudent.toFixed(2) : school.CostToStudent.toFixed(2)) + "</td>" +
            "</tr>";        
    }

    tbody = "<tbody>" + tbody + "</tbody>";
    $(output).append(tbody);
    
    removeLoader();
    
    $(element).append(output);

    $("#schoolsTable").DataTable({
        fixedHeader: true,
        paging: false
    });

    if(onComplete){
        onComplete();
    }
}

export async function getActiveSemesters(element){

    let output = $("<div class='semesters'></div>");

    $(element).append(generateLoader());

    let semestersArray = await getData(semester.list(true));
    //semestersArray = JSON.parse(semestersArray);
    //semesterArray = schoolsArray.sort((a, b) => a.SchoolName.localeCompare(b.SchoolName));
    //console.log(out);

    for(let sem of semestersArray){
        $(output).append(
            "<label>" +
                "<input type='radio' name='semester' value='" + sem.SemesterID + "'>" + sem.Name + "</input>" + 
            "</label>"
        );
    }
    
    removeLoader();
    
    $(element).append(output);

    $("input[name='semester']").change(function(){
        alert("sel: " + $(this).val())
    }); 
}


export async function getCourses(element, semesterID){

    let output = $("<div class='courses'></div>");

    $(element).append(generateLoader());

    let coursesArray = await getData(course.list(semesterID));       

    let courseCategoryArray = _.map(coursesArray, (it) => { return it.CourseCategory ? { CourseCategory: it.CourseCategory, CourseIconURL: it.CourseIconURL } : null });
    let courseCategoryArraySecondary = _.map(coursesArray, (it) => { return it.SecondaryCourseCategory ? { CourseCategory: it.SecondaryCourseCategory, CourseIconURL: it.SecondaryCourseIconURL } : null });    
    let courseCategories = _.concat(courseCategoryArray, courseCategoryArraySecondary);
    courseCategories = _.uniqWith(courseCategories, _.isEqual);
    courseCategories = _.sortBy(courseCategories, ["CourseCategory"]);
    courseCategories = _.compact(courseCategories);

    for(let cc of courseCategories){

        let html=
            "<h3 class='category-title'>" + 
                "<img src='" + cc.CourseIconURL + "'/>" +
                cc.CourseCategory +
            "</h3>" +
            "<div class='class-group'>";        

        for(let c of coursesArray.filter( course => course.CourseCategory === cc.CourseCategory || course.SecondaryCourseCategory === cc.CourseCategory )){
            let truncDesc = $("<div/>").html(c.Description || "").text();
            if(truncDesc.length > 75){
                truncDesc = truncDesc.substring(0, truncDesc.indexOf(" ", 70)) + "...";
            }

            html +=
                "<div class='class-item'>" +
                    "<a href='javascript:' data-classid='" + c.ClassID + "' class='openModal'>" +
                        "<div class='class-item-title'>" + 
                            "<h4>" +
                                c.Title +
                            "</h4>" +
                        "</div>" +
                        "<img class='course-category-icon' src='" + c.CourseIconURL + "' alt='Course Icon'>" +
                        "<div class='class-item-inner'>" +
                            "<span>" +
                                truncDesc   +
                            "</span>" +
                        "</div>" +
                        "<div class='class-item-inner'>" +
                            "<a href='https://indianaonlineacademy.org/ioasis/classcatalog.aspx?classID=" + c.ClassID + "' class='button'>" +
                                "Enroll Now" +
                            "</a>" +
                        "</div>" +
                    "</a>" +
                "</div>" +
                "<div class='modal' id='details" + c.ClassID + "' style='display:none;'>" +
                    "<b>Class Name: " + c.Title + "</b><br/>" +
                    "<div class='indentSubtitle'><i>" + c.Subtitle + "</i></div><br/>" +
                    "<b>Instructor: " + c.Instructor + "</b><br/>" +
                    "<b>Credits: " + c.Credits + "</b><br/><br/>" +                    
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Suggested Prerequisites</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.Prerequisites + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Required Materials</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.RequiredMaterials + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Estimated Completion Time</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.CompletionTime + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Course Description</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.Description + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Major Topics and Concepts</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.MajorTopics + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Objectives / State Standards</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.StateStandards + "</p></td>" +                            
                        "</tr>" +
                    "</table><br/>" +
                    "<table>" +
                        "<tr>" +
                            "<td colspan='2'>" +
                                "<hr>" +
                                "<strong>Course Assessment and Participation Requirements</strong>" +
                            "</td>" +
                        "</tr>" +
                        "<tr>" +
                            "<td width='5%'>&nbsp;</td>" +
                            "<td width='95%'><p>" + c.AssessmentRequirements + "</p></td>" +                            
                        "</tr>" +
                    "</table>" +                               
                "</div>";
            
        }

        html +=
        "<div class='clearfix'></div>" +
        "</div>";

        $(output).append(html);
    
    }
    
    removeLoader();
    
    $(element).append(output);

    $(".button").button();

    $(".courses").accordion({
        heightStyle: "content",
        collapsible: "true",
        icons: "false"
    });

    $(".modal").dialog({
        autoOpen: false,
        width: 800,    
        modal: true,
        position: { my: "top", at: "top" }
    });

    $(".openModal").click(function(){
        let cid = $(this).attr("data-classid");
        $("#details" + cid).dialog("open");
    });

}


export async function getInstructors(element){

    let output = $("<div id='accordion'></div>");

    $(element).append(generateLoader());

    let instructorsArray = await getData(instructor.list());
    instructorsArray = JSON.parse(instructorsArray);
    instructorsArray = instructorsArray.sort((a, b) => (a.LastName + a.FirstName).localeCompare((b.LastName + b.FirstName)));
    
    let categoriesArray = [];
    for(let instr of instructorsArray){
        categoriesArray = categoriesArray.concat(instr.CourseCategoryList);
    }

    let uniqueCatNames = [...new Set(categoriesArray.map(item => item.Name))];
    uniqueCatNames = uniqueCatNames.sort((a, b) => (a).localeCompare((b)));
    
    let uniqueCatObjects = [];
    for(let name of uniqueCatNames){
        uniqueCatObjects.push(categoriesArray.find(obj=>obj.Name === name));
    }

    for(let category of uniqueCatObjects){
        let temp = "<h3><img src='" + category.IconURL + "' style='width:20px'/>" + category.Name + "</h3>" +
                    "<div>";
        
        for(let instr of instructorsArray.filter(instr=>instr.CourseCategoryList.find(cc=>cc.Name === category.Name))){
                             
            temp += "<div style='display:inline-block; vertical-align:top; width:180px;margin-right:20px; margin-bottom:30px;'>" +
                        "<img src='" + instr.PhotoURL +"' width='100' height='144'> <br>" +
                            "<div style='font-size:1.4em;'>" + instr.FirstName + " " + instr.LastName + "</div>" +
                            "<div style='font-size:1em;'>" + instr.CourseCategoryList.find(cc=>cc.Name === category.Name).Subject + "</div>" +
                    "</div>";

        }

        temp +="</div>";

        $(output).append(temp);
    }
    
    removeLoader();
    
    $(output).accordion({
        heightStyle: "content",
        collapsible: true
    });
    $(element).append($(output));

}